@import "react-datepicker/dist/react-datepicker.css";
@font-face {
  font-family: 'Spoqa Han Sans';
  font-style: normal;
  font-weight: 100;
  src: url('@/assets/fonts/Spoqa-Han-Sans-Neo-Thin.woff2') format('woff2'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Thin.woff') format('woff'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Thin.otf') format('opentype');
}
@font-face {
  font-family: 'Spoqa Han Sans';
  font-style: normal;
  font-weight: 300;
  src: url('@/assets/fonts/Spoqa-Han-Sans-Neo-Light.woff2') format('woff2'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Light.woff') format('woff'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Light.otf') format('opentype');
}
@font-face {
  font-family: 'Spoqa Han Sans';
  font-style: normal;
  font-weight: 400;
  src: url('@/assets/fonts/Spoqa-Han-Sans-Neo-Regular.woff2') format('woff2'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Regular.woff') format('woff'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Regular.otf') format('opentype');
}
@font-face {
  font-family: 'Spoqa Han Sans';
  font-style: normal;
  font-weight: 500;
  src: url('@/assets/fonts/Spoqa-Han-Sans-Neo-Medium.woff2') format('woff2'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Medium.woff') format('woff'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Medium.otf') format('opentype');
}
@font-face {
  font-family: 'Spoqa Han Sans';
  font-style: normal;
  font-weight: 700;
  src: url('@/assets/fonts/Spoqa-Han-Sans-Neo-Bold.woff2') format('woff2'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Bold.woff') format('woff'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Bold.otf') format('opentype');
}
@font-face {
  font-family: 'Spoqa Han Sans';
  font-style: normal;
  font-weight: 700;
  src: url('@/assets/fonts/Spoqa-Han-Sans-Neo-Bold.woff2') format('woff2'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Bold.woff') format('woff'),
       url('@/assets/fonts/Spoqa-Han-Sans-Neo-Bold.otf') format('opentype');
}